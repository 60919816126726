@import './bootstrap_variables.scss';

.dashboard-bg {
  background     : #f8f9fa;
  background-size: 100% 100%;
}

.outer header {
  border-bottom: solid 1px #d5d5d5;
  background   : $header-bg-color;
  overflow     : visible;
  margin-bottom: 15px;
}

header .logo-inner {
  line-height        : 50px;
  text-align         : center;
  height             : 57px;
  background-position: 50% 50%;
  float              : left;
  margin-top         : 3px;
}

header .logo-inner img {
  max-height: 40px;
  margin    : 5px 15px;
}

header .header-right {
  float          : none;
  display        : flex;
  justify-content: flex-end;
  align-items    : center;
  width          : auto;
  overflow       : visible;
  height         : 57px;
  background     : $header-bg-color !important;
}

.header-right {

  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    margin-right: 15px;

    a {
      color: #000;

      &:hover {
        color: $primary;
      }
    }

    .btn.dropdown-item {
      color: #000;

      &:hover {
        color: $primary;
      }
    }
  }

  .btn:focus,
  .btn.focus {
    box-shadow: none;
  }
}

main {
  height     : 100%;
}

.left-column {
  width       : 278px;
  float       : left;
  height      : 100%;
  background  : #f8f9fa;
  position    : relative;
  border-right: 1px solid #dfe0e1;
  border-top  : 1px solid #dfe0e1;
  margin-right: 15px;
  height      : 80vh;
}

.left-column ul {
  margin : 0;
  padding: 0;
  width  : 60px;
  float  : left;
  width  : 100%;
}

.left-column li {
  list-style: none;
  text-align: left;
  padding   : 10px 0px 0 10px;
}

.left-column li button {
  padding: 0.25rem 0;
}

.left-column li button a {
  padding        : 0px 10px;
  text-decoration: none;
}

.left-column a {
  display            : block;
  background-position: 11px 12px;
  color              : #7c7c7d;
  font-size          : inherit;
  font-weight        : 400;
  line-height        : 1.5;
}

.icon-menu li:hover {
  color: $primary !important;
}

.icon-menu .left-main-menu-item {
  text-decoration    : none;
  background-repeat  : no-repeat;
  background-position: 13px 10px;
  color              : #2d2d2f;
  display            : flex;
  font-weight        : normal;
  cursor             : pointer;
  padding            : 12px 12px 12px 50px;
  width              : 100%;
  border-bottom      : 1px solid #eaeaea;
}

.right-column {
  float      : none;
  width      : auto;
  height     : 100%;
}

.right-column h3 {
  position   : absolute;
  left       : 50%;
  top        : 50%;
  margin-top : -15px;
  margin-left: -150px;
  font-size  : 30px;
  color      : #af4925;
}

.user_logo {
  width        : 28px;
  height       : 28px;
  padding      : 2px;
  margin-right : 3px;
  margin-bottom: 2px;
}

// for left menu dropdown
.left-menu-dropdown {
  top : -20px !important;
  left: 70px !important;
}

.activeLeftMenu {
  color: $primary !important;
}

// home screen styles
.home-container {
  background-color: #fff !important;
  height          : 700px;

  p {
    position  : relative;
    top       : 50%;
    text-align: center;
    color     : #920d0d;
  }

  .home-banner {
    display        : flex;
    justify-content: center;
    align-content  : center;
    padding-top    : 9%;

    img {
      max-width    : 100%;
      border-radius: 20px;
    }
  }
}

// for loader
.loading-section {
  height         : 100vh;
  justify-content: center;
  align-items    : center;
  display        : flex;
  flex-direction : column;
}

.loading {
  border: 16px solid #f3f3f3;
  border-top: 16px solid $primary;
  border-radius: 50%;
  width        : 80px;
  height       : 80px;
  animation    : spin 2s linear infinite;
}

.loading p {
  font-size : 20px;
  margin-top: 20px;
}

// for input file browse
.upload-btn-wrapper {
  position  : relative;
  display   : inline-block;
  width     : 100%;
  margin-top: 30px;
}

.upload-btn-filename {
  background-color: #fff !important;
}

.upload-btn {
  min-width: 106px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position : absolute;
  left     : 0px;
  top      : 0px;
  opacity  : 0;
  cursor   : pointer;
  width    : 106px;
  height   : 43px;
}

.ReactCollapse--collapse {
  transition        : height 800ms;
  width             : 100%;
}

.opacity-on {
  opacity: 1;
}

.opacity-off {
  opacity: 0;
}

.emphasized-label {
  font-weight: bold;
}

.modal-body {
  word-break: break-all;
}

$midnight: #2c3e50;

.tabs {
  overflow  : hidden;
  margin-top: -5px;
}

.tab {
  width   : 100%;
  color   : white;
  overflow: hidden;

  &-label {
    display        : flex;
    justify-content: space-between;
    padding        : 1em;
    font-weight    : bold;
    border-bottom  : 1px solid #eaeaea;
    margin-bottom  : 0;
    color          : #000;
    cursor         : pointer;

    &::after {
      content   : "\276F";
      width     : 1em;
      height    : 1em;
      text-align: center;
      transition: all .35s;
    }
  }

  &-content {
    max-height: 0;
    padding   : 0 1em;
    color     : $midnight;
    background: white;
    transition: all .35s;

    .sub-menu {
      display: block;

      .btn {
        color      : #ffffff;
        padding    : 10px 10px 10px 52px;
        font-weight: 600;
        width      : 100%;
        text-align : left;
        font-size  : 14px;
      }

      a {
        padding       : 10px 10px 10px 30px;
        color         : #6a6c6f;
        text-transform: none;
        font-weight   : 600;
      }

      &:hover {
        background: #eee;
      }
    }
  }

  &-close {
    display        : flex;
    justify-content: flex-end;
    padding        : 1em;
    font-size      : 0.75em;
    cursor         : pointer;

    &:hover {
      background: darken($midnight, 10%);
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    opacity : 0;
    position: absolute;
    z-index : 1;
  }
}

._loading_overlay_overlay {
  display               : flex !important;
  align-items           : center;
  -ms-flex-item-align   : center;
}

/* loading overlay custom class for full-page screen (like resetPassword)*/
.full_page_loading_overlay ._loading_overlay_overlay {
  height      : 100vh !important;
}

.change-password {
  label {
    display: block;
    width  : 100%;
  }

  .btn.input-group-addon {
    border-radius: 0;
    background   : #e9ecef;
    border       : 1px solid #e5e6e7;
  }
}

.hr-line-dashed {
  border-top      : 1px dashed #e7eaec;
  color           : #ffffff;
  background-color: #ffffff;
  height          : 1px;
  margin          : 20px 0;
}

.form-control {
  border       : 1px solid #cecfd0 !important;
  padding      : 6px 12px;
  transition   : border-color 0.15s ease-in-out 0s;
  box-shadow   : 0.15s ease-in-out 0s;
  width        : 100%;
  border-radius: 0 !important;
  border       : 10px solid #000;
}

.clear-all {
  clear: both;
}

.card.grid-panel {
  margin-bottom : 20px;
  padding-bottom: 15px;

  .card-body {
    padding   : 0 1.25rem;
    min-height: 450px;
  }
}

.clear-all {
  clear: both;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 950px !important;
  }
}

.breadcrumb {
  padding         : 0.75rem 0rem !important;
  margin-bottom   : 0 !important;
  list-style      : none;
  background-color: transparent !important;
  font-size       : 17px;
}

.p-4 {
  padding: 0px 10px !important;
}

.show-hide-password {
  border-style    : none !important;
  background-color: #fdfdfd00 !important;
  color           : #212529 !important;
  padding         : 0 !important;
}

.align-center {
  position  : relative;
  top       : 50%;
  text-align: center;
  color     : $primary;
}

.default_layout_loading_overlay {
  height             : 100vh !important;
  display            : flex !important;
  align-items        : center;
  -ms-flex-item-align: center;
}

.margin-auto {
  margin: 0 auto;
}

.width-70per {
  width: 80% !important;
}

.view-profile-details {
  border       : 1px solid #ccc;
  border-radius: 10px;
  width        : 50%;
  padding      : 20px;
  background   : #fcfcfc;
  margin       : 20px auto 20px auto;

  .ReactCollapse--collapse {
    padding: 0 20px;
  }
}

@media (max-width: 992px) {
  .view-profile-details {
    width        : 100%;
    margin-bottom: 20px;
  }
}

.modal-wrapper-custom {
  /*
  applicable to all browsers
  */
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .modal-wrapper-custom {
    // a fix for modal popup in internet explorer browser
    position: -ms-device-fixed;
  }
}

.styled-text-default {
  color: red;
}

/* Modal Header extended styles - for fixing reactstrap modal issue in IE */
.custom-modal-header button,
.custom-modal-header button:hover,
.custom-modal-header button:active {
  background-color: #ffffff !important;
  font-size       : 1.5rem !important;
  color           : black !important;
  box-shadow      : none !important;
}

.my-4-custom {
  margin-bottom: 4rem !important;
}

/* custom drop-down class */
.custom-select-dropdown {
  border-color    : $primary !important;
  color           : #000 !important;
  min-width       : 220px !important;
  border          : 2px solid $primary!important;
}

.custom-select-dropdown-option {
  background-color: #fff !important;
  color           : #000 !important;
}

.margin-t-30 {
  margin-top: 30px;
}

/*
for text truncation issue in select element(when italicised 
font is used) - modifying bootstrap default padding
*/
select.form-control {
  padding: 0 .75rem;
}

.react-datepicker-wrapper{
  display: block !important;
}
