@import './bootstrap_variables.scss';

body {
  background: #f9f9f9 !important;
}

.loginContainer {
  width         : auto;
  position      : absolute;
  left          : 50%;
  top           : 50%;
  transform     : translate(-50%, -50%);

  .form-signin {
    width        : 360px;
    border       : 1px solid #d9d9d9;
    padding-top  : 30px;
    border-radius: 10px;
    background   : #fff;
    text-align   : center;
    box-shadow   : 0 0 15px rgba(0, 0, 0, .1);

    img {
      margin-bottom: 20px;
    }

    .loginFormInner {
      padding: 0 20px;
    }

    h1 {
      font-size: 25px;
    }

    form {
      margin-bottom: 40px;
      padding      : 0px;
    }

    .form-group {
      input {
        border-radius: 20px !important;
        height       : 40px;
        padding      : 0 20px;
        font-size    : 15px;
      }

      span {
        font-size : 12px;
        position  : absolute;
        right     : 50px;
        margin-top: -29px;
        color     : red;
      }
    }

    button {
      border-radius: 40px !important;
      height       : 40px;
      padding      : 0 20px;
      margin-top   : 30px;
    }

    .fgt-pass {
      background   : #f2f2f2;
      border-top   : solid 1px #d9d9d9;
      padding      : 15px 0;
      font-size    : 14px;
      border-radius: 0 0 10px 10px;
    }

    .formErrors {
      text-align: left;
    }
  }
}

.loginBox {
  box-shadow           : 0px 0px 8px #333;
  -webkit-border-radius: 5px;
  -moz-border-radius   : 5px;
  -ms-border-radius    : 5px;
  border-radius        : 5px;
}

.loginBox input[type='text'],
.loginBox input[type='password'] {
  background  : #e7e7e7;
  border-color: #b9b9b9;
}

.loginContainer header {
  text-align           : center;
  background-image     : -o-linear-gradient(0deg, #fff, #eaeaea);
  background-image     : -moz-linear-gradient(0deg, #fff, #eaeaea);
  background-image     : -webkit-linear-gradient(0deg, #fff, #eaeaea);
  background-image     : linear-gradient(0deg, #fff, #eaeaea);
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius   : 5px 5px 0 0;
  -ms-border-radius    : 5px 5px 0 0;
  border-radius        : 5px 5px 0 0;
  padding              : 10px 5px;
  border-bottom        : solid 1px rgba(0, 0, 0, .05);
}

.login-form-container {
  padding              : 40px 20px 30px 20px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius   : 0 0 5px 5px;
  -ms-border-radius    : 0 0 5px 5px;
  border-radius        : 0 0 5px 5px;
  background           : #ffffff;
}

.login-container {
  border       : solid 1px rgba(0, 0, 0, .1);
  border-radius: 5px;
  background   : #fff;
  padding      : 40px;
  box-shadow   : 0 0 10px rgba(0, 0, 0, .2);
}

.logo {
  background-position: center 0;
}

.login-footer {
  background           : #ededed;
  border-top           : solid 1px #ffffff;
  padding              : 10px 20px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius   : 0 0 5px 5px;
  -ms-border-radius    : 0 0 5px 5px;
  border-radius        : 0 0 5px 5px;
  position             : relative;
  height               : 61px;
}

.login-footer:before {
  content   : "";
  position  : absolute;
  border-top: solid 1px #cccccc;
  top       : -2px;
  left      : 0;
  height    : 1px;
  width     : 100%;
}

.login-footer a {
  color     : #1b1b1b;
  margin-top: 7px;
  display   : inline-block;
  font-size : 14px;
}

.input-group.mb-3 {
  margin-bottom: 0px !important;
}

.btn-group {
  box-shadow           : inset 0 0 15px rgba(0, 0, 0, 0.25);
  background           : #ffffff;
  border               : solid 1px #afafaf;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius   : 5px 5px 5px 5px;
  -ms-border-radius    : 5px 5px 5px 5px;
  border-radius        : 5px 5px 5px 5px;
}

.enter-btn {
  background-position: 7px 4px;
  border-right       : solid 1px #afafaf !important;
}

.cancel-btn {
  background-position: 7px -32px;
}

.btn-border-left {
  border-left: solid 1px #afafaf;
}

.brand-logo {
  width : 100px;
  height: 42px;
}

.reset-section {
  width : 40% !important;
  margin: 0 auto;
}

.reset-password-wrap {
  clear        : both;
  margin-bottom: 25px;
  margin-top   : 0;
  padding      : 0;

  .form-control {
    background-color: #FFFFFF;
    background-image: none;
    border          : 1px solid #e5e6e7;
    border-radius   : 1px;
    display         : block;
    padding         : 6px 12px;
    transition      : border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    width: 100%;
  }

  .btn.input-group-addon {
    border-radius: 0;
    background   : #e9ecef;
    border       : 1px solid #e5e6e7;
  }

  label {
    width    : 100%;
    display  : block;
    font-size: 14px;
  }

  .badge {
    width         : 25px;
    height        : 25px;
    border-radius : 50%;
    background    : #6c757d;
    display       : inline-block;
    vertical-align: bottom;
    padding-top   : 7px;
    color         : #fff;
  }

  .ibox-title {
    background-color     : rgba(0, 0, 0, .03);
    border-color         : #e7eaec;
    border-image         : none;
    border-style         : solid solid none;
    border-width         : 1px;
    color                : inherit;
    margin-bottom        : 0;
    padding              : 15px 20px 8px 15px;
    font-size            : 16px;
    min-height           : 48px;
    position             : relative;
    clear                : both;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius   : 3px 3px 0 0;
    border-radius        : 2px 2px 0 0;
  }

  .ibox-content {
    background-color: #ffffff;
    color           : inherit;
    padding         : 15px 20px 20px 20px;
    border-color    : #e7eaec;
    border-image    : none;
    border-style    : solid;
    border-width    : 1px;
    box-shadow      : 0 0 15px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 900px) {
  .loginContainer {
  }

  .reset-section {
    width: 100% !important;
  }

  .p-4.reset-section {
    padding: 0px !important;
  }
}

@media (max-width: 360px) {
  .loginContainer {
    .form-signin {
      width: 310px;
    }
  }
}

.input-group-prepend {
  display: none !important;
}

.danger-alert {
  color           : #721c24;
  background-color: #f8d7da;
  border-color    : #f5c6cb;
  padding         : .35rem 1.25rem;
  margin-bottom   : 1rem;
  border-radius   : .25rem;
  font-size       : 13px;
}

.login_loading_overlay ._loading_overlay_overlay {
  max-height: 100% !important;
}