@import "../../../Containers/css/app/bootstrap_variables.scss";

.grid-wrapper {
  width            : calc(100% - 10px);
  margin           : 5px auto;
  position         : relative;
  border-radius    : 5px;
  padding-top      : 0px;
  box-sizing       : border-box;
}

.custom-scrollbar-table {
  height      : calc(100% - 4px);
  padding     : 0 1px;
}

.custom-scrollbar-table .table {
  border-spacing       : 0;
  background           : #ffffff;
  width                : 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius   : 5px;
  -ms-border-radius    : 5px;
  border-radius        : 5px;
  margin-bottom        : 0;
  border               : 0;
  border-bottom        : 1px solid #dee2e6;
}

.custom-scrollbar-table .table:before {
  content   : "";
  height    : 36px;
  background: #fff;
  width     : 100%;
  position  : absolute;
  width     : -moz-available;
  top       : 0;
  left      : 0;
}

.custom-scrollbar-table .table th {
  font-weight   : normal;
  height        : 0px;
  vertical-align: top;
  height        : 0;
  line-height   : 0;
  color         : transparent;
  border        : none;
  //white-space   : nowrap;
  position      : relative;
}

.custom-scrollbar-table .table th div {
  background      : transparent;
  color           : #000000;
  padding         : 2px 10px 2px 0px;
  line-height     : normal;
  width           : 100%;
}

.custom-scrollbar-table .table th span {
  line-height: 25px;
  //white-space: nowrap;
  vertical-align: top;
}

.custom-scrollbar-table .table th span.grid-col-filter {
  display: none;
  //width  : 60%;
  max-width: 80%;
}

.custom-scrollbar-table .table th .enable {
  display: inline-block !important;
  max-width: 82%;
}

.custom-scrollbar-table .table th .disable {
  display: none !important;
}

.custom-scrollbar-table .table th input[type='text'] {
  -webkit-border-radius: 4px;
  -moz-border-radius   : 4px;
  -ms-border-radius    : 4px;
  border-radius        : 4px;
  padding              : 7px 5px 5px 5px;
  font-size            : 14px;
  font-weight          : normal;
  height               : 28px;
}

.custom-scrollbar-table .table th .fa-filter {
  color   : $primary;
  position: relative;
}

.custom-scrollbar-table .table th .fa-filter .fa-close {
  font-size: 8px;
  position : absolute;
  left     : 9px;
  top      : 7px;
}

.custom-scrollbar-table .table th .filtered-option {
  font-size   : 16px;
  opacity     : 1;
  -ms-filter  : "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter      : alpha(opacity=100);
  margin-left : 5px;
  margin-right: 5px;
  position    : absolute;
  right       : 1px;
}

.custom-scrollbar-table .table th .sorting-option {
  font-size  : 16px;
  margin-left: 4px;
}

.custom-scrollbar-table .table th .sorting-option i {
  opacity   : 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter    : alpha(opacity=0);
}

.custom-scrollbar-table .table td.grid-options .btn.dropdown-toggle {
  padding: 1px 8px 0px 5px;
  border : 1px solid $primary;
  color  : $primary;
}

.custom-scrollbar-table .table td>a {
  text-decoration: underline;
}

.custom-scrollbar-table .table td>a:hover {
  text-decoration: none;
}

.custom-scrollbar-table .table-bordered th {
  padding           : 10px 6px;
  font-weight       : 600;
  border            : 1px solid #dee2e6;
}

.custom-scrollbar-table .table-bordered th a {
  color: #1b1b1b;
}

.custom-scrollbar-table .table-bordered th .sorting-option i.fa-sort-desc,
.custom-scrollbar-table .table-bordered th .sorting-option i.fa-sort-asc {
  opacity   : 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter    : alpha(opacity=100);
  position  : relative;
  font-size : 20px;
}

.custom-scrollbar-table .table-bordered th .sorting-option i.fa-sort-desc {
  top: -3px;
}

.custom-scrollbar-table .table-bordered th .sorting-option i.fa-sort-asc {
  top: 6px;
}

.custom-scrollbar-table .table-bordered #grid-col-sortOrder {
  min-width: 130px;
  width    : 130px;
}

.custom-scrollbar-table .table-bordered th:hover .sorting-option i {
  opacity   : 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter    : alpha(opacity=100);
}

.custom-scrollbar-table .table-bordered .dropdown-menu {
  left       : inherit;
  right      : 0;
}

.custom-scrollbar-table .table-bordered .dropdown-menu a {
  padding-left: 10px;
}

.custom-scrollbar-table .table td {
  padding: 10px 6px;
}

.custom-scrollbar-table th {
  border-top: 0;
  padding   : 10px 6px;
}

.custom-scrollbar-table tbody {
  -webkit-border-radius: 5px;
  -moz-border-radius   : 5px;
  -ms-border-radius    : 5px;
  border-radius        : 5px;
  background           : #ffffff;
}

.custom-scrollbar-table .mandatory {
  float: left;
}

.custom-scrollbar-table .form-control {
  display: inline-block;
  width  : 100%;
}

.custom-scrollbar-table .options-heading {
  width: 200px;
}

@-moz-document url-prefix() {
  .custom-scrollbar-table table {
    //margin-top: -6px;
  }

  .custom-scrollbar-table {
    scrollbar-width: thin;
  }

  .custom-scrollbar-table {
    tbody {
      margin-right: -3px;
      scrollbar-width: thin;
    }
  }
}

.scrollableTable {
  height: calc(100% - 60px);
}

.defaultClass .grid-wrapper {
  overflow: inherit;
}

.defaultClass .grid-wrapper .custom-scrollbar-table {
  overflow: inherit;
}

.dropdown-wrapper {
  display : inline-block;
  position: relative;
}

// styles for loader message
.grid-display-on {
  display: block;
  float  : left;
  width  : 100%;
}

.grid-display-off {
  display: none;
}

.grid-loader-holder {
  background-color: #a0a0a0;
  height          : 700px;
  margin-top      : 90px;
}

.grid-loader-msg {
  position  : relative;
  top       : 50%;
  text-align: center;
  color     : #920d0d;
}

// grid-row component styles
.grid-options {
  text-align: center;
}

.cursor {
  cursor: pointer;
}

// pagination component styles
.pagination-custom {
  display      : inline-block;
  padding-left : 0;
  margin       : 20px 0;
  border-radius: 4px;
}

.pagination-custom a {
  position        : relative;
  float           : left;
  padding         : 6px 12px;
  margin-left     : -1px;
  line-height     : 1.42857143;
  color           : $primary !important;
  text-decoration : none;
  background-color: #fff;
  border          : 1px solid #ddd;
  cursor          : pointer;
}

.pagination-custom>.custom-disabled>a,
.pagination-custom>.custom-disabled>a:focus,
.pagination-custom>.custom-disabled>a:hover,
.pagination-custom>.custom-disabled>span,
.pagination-custom>.custom-disabled>span:focus,
.pagination-custom>.custom-disabled>span:hover {
  color           : #a5a1a1 !important;
  cursor          : not-allowed;
  background-color: #fff;
  border-color    : #ddd;
  pointer-events  : none;
}

.pagination-custom>.custom-active>a {
  z-index         : 2;
  color           : #fff !important;
  cursor          : default;
  background-color: $primary;
  border-color    : $primary;
}

.formErrors {
  margin-top: .25rem;
  font-size : 80%;
  color     : #dc3545;
}

.f-btn .btn a {
  color          : #fff;
  text-decoration: none;
}

.role-space .row {
  margin: 30px 0 0 -15px !important;
}

.custom-select {
  display         : block;
  width           : auto;
  height          : calc(1.5em + 0.75rem + 2px);
  padding         : 0.375rem 0.75rem;
  font-size       : 1rem;
  font-weight     : 400;
  line-height     : 1.5;
  color           : #495057;
  background-color: #fff;
  background-clip : padding-box;
  border          : 1px solid #ced4da;
  border-radius   : 0.25rem;
  transition      : border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-paging .form-group {
  float: left;
}

.custom-paging .pagination {
  float: right;
}

.custom-scrollbar-table .dropdown-menu li a {
  color          : $primary !important;
  text-decoration: none;
  display        : block;
  cursor         : pointer;
}

.custom-scrollbar-table .dropdown-menu li a:hover {
  text-decoration: none;
}

.custom-scrollbar-table .dropdown-menu li:hover {
  background: #e6e6e6;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page-error {
  height         : 400px;
  text-align     : center;
  font-size      : 30px;
  font-weight    : 900;
  color          : #ba4b18;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

@media only screen and (max-width: 1600px) {
  .custom-scrollbar-table .table td {
    font-size: 13px;
  }

  .custom-scrollbar-table .table th {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .role-space {
    padding-bottom: 20px;
  }

  .role-space .row {
    margin: 0 !important;
  }

  .role-space label {
    margin      : 0 !important;
    padding-left: 0;
  }
}

.custom-paging {
  display        : flex;
  justify-content: space-between;
}

.custom-paging .pagination {
  margin: 0;
}

/*----------------------------
        TABLE SECTION
-----------------------------*/

::-webkit-scrollbar {
  width : 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  background: #ccc
}

::-webkit-scrollbar-track-piece {
  background: #eee
}

::-webkit-scrollbar-thumb {
  background: #888
}

.custom-scrollbar-table {
  tbody {
    display           : block;
    max-height        : 55vh;
    overflow-y        : scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  thead,
  tbody tr {
    display     : table;
    width       : 100%;
    table-layout: fixed;
  }

  thead {
    width: calc(100% - 5px)
  }

  table {
    width: 400px;
  }

  .table td,
  .table th {
    text-align: center;
  }

  ._loading_overlay_wrapper {
    padding-top: 37px;
  }
}

// target ie browser
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .custom-scrollbar-table {
    tbody {
      margin-right: 5px;
    }

    tbody tr td:last-child {
      padding-right: 16px;
    }
  }
}

// target edge browser
@supports (-ms-ime-align: auto) {
  .custom-scrollbar-table {
    tbody {
      margin-right: 5px;
    }
    
    tbody tr td:last-child {
      padding-right: 16px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .custom-scrollbar-table {
    width     : 100%;
    overflow-x: scroll;

    table {
      min-width: 1000px;

      tbody {
        height    : 90%;
        overflow-y: scroll;
      }
    }
  }
}

/* assignment oprator modal css */
.user-assignment-grid {
  .custom-scrollbar-table {
    tbody {
      height    : 90%;
      overflow-y: scroll;
    }
  }
}

/* single frid-=option btn style */
.grid-single-option {
  border          : 1px solid $primary !important;
  color           : $primary !important;
  background-color: #fff !important;
}
